import * as React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import Containers from "../components/container"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';
import { StaticImage } from "gatsby-plugin-image"
import * as heroStyle from "./hero.module.css"

// styles
const pageStyles = {
    color: "#fff",
    paddingTop: 0,
    paddingLeft: 0,
    padingRight: 0,
  }

const spanStyles = {
    color: "#2C3691",
}
  
  // markup
  const Hero = () => {
    return (
      <main style={pageStyles}>
        <Container fluid="xl" className={heroStyle.hero}>
            <Row>
                <Col md={6}>
                    <Containers>
                        <Row>
                            <Col md={12} className={heroStyle.txthero}>
                                <p className={heroStyle.pone}><span className={heroStyle.spanone}>Original Since 2010</span> <StaticImage className={heroStyle.bounce} src="../images/broucher-01.png" alt="BroucherHero" /></p>
                                <h1>ผู้ผลิต <span style={spanStyles}>Flexible Packaging</span> หรือบรรจุภัณฑ์ชนิดอ่อน</h1>
                                <p>ได้รับความนิยมในการนำไปใช้เป็นผลิตภัณฑ์ สำหรับการบรรจุสินค้า อุปโภคและบริโภคอย่างแพร่หลาย ในชีวิตประจำวัน</p>
                                <Button className={heroStyle.buttoncontact} rel="noreferrer" href="/contact/">ติดต่อเรา <Icon icon={arrowRight} /></Button>{' '}
                            </Col>
                        </Row>
                    </Containers>
                </Col>
                <Col md={6}>
                    <StaticImage src="../images/product-01.png" alt="ProductHero" />
                </Col>
            </Row>
        </Container>
      </main>
    )
  }
  
  export default Hero