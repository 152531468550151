import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import * as bgStyle from "./bg.module.css"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';
import { Container, Row, Col, Button } from "react-bootstrap"

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "home-background.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={imageData}
      backgroundColor={`#040e18`}
    >
    <Container className={bgStyle.home}>
        <Row>
          <Col md={2}></Col>
          <Col md={8}>
            <h3 className={bgStyle.header}>ความมุ่งมั่นในการผลิตบรรจุภัณฑ์รูปแบบต่างๆ ที่หลากหลาย</h3>
            <p className={bgStyle.pstyles}>บริษัท ไทยโพลีแพ็ค จำกัด มีความมุ่งมั่นในการผลิตบรรจุภัณฑ์รูปแบบต่างๆ หลากหลาย 
              เพื่อตอบสนองความต้องการของลูกค้า ทั้งในและต่างประเทศด้วยกระบวนการผลิต 
              ที่ได้มาตรฐานทุกขั้นตอนรวมถึงการตรวจสอบคุณภาพสินค้าอย่างเข้มข้น
              เพื่อสร้างความเชื่อมั่นแก่ลูกค้าทุกราย</p>
            <Button className={bgStyle.buttonabout} rel="noreferrer" href="/about/">เกี่ยวกับเรา <Icon icon={arrowRight} /></Button>{' '}
          </Col>
          <Col md={2}></Col>
        </Row>
      </Container>
    </BackgroundImage>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: top left;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection