import * as React from "react"
import Header from "../components/header"
import HeaderTop from "../components/headertop"
import FooterForm from "../components/form-footer"
import Footer from "../components/footer"
import Hero from "../components/hero"
import Brand from "../components/brand"
import Bg from "../components/bg"
import Ourproduct from "../components/ourproduct"
import Quality from "../components/quality"
import Contact from "../components/contact"
import SEO from "../components/seo"


// styles
const pageStyles = {
  color: "#fff",
  paddingTop: 0,
  paddingLeft: 0,
  padingRight: 0,
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <SEO title="ผู้ผลิต Flexible Packaging หรือบรรจุภัณฑ์ชนิดอ่อน" />
      <HeaderTop />
        <Header />
          <Hero />
            <Brand />
              <Bg />
                <Ourproduct />
                  <Quality />
                    <Contact />
        <FooterForm />
      <Footer />
    </main>
  )
}

export default IndexPage