import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import * as contactStyles from "./contact.module.css"
import { Icon } from '@iconify/react';
import mobileIcon from '@iconify/icons-bytesize/mobile';
import locationIcon from '@iconify/icons-bytesize/location';
import faxIcon from '@iconify/icons-cil/fax';

// styles
const pageStyles = {
    color: "#000"
}
  
// markup
  const Contact = () => {
    return (
      <main style={pageStyles}>
          <Container fluid="xl" className={contactStyles.contactpadding}>
              <Row>
                  <Col md={6} className={contactStyles.colpd}>
                    <h3 className={contactStyles.header}>ติดต่อสำนักงาน</h3>
                    <p>บริษัท ไทยโพลีแพ็ค จำกัด มีความมุ่งมั่นในการผลิตบรรจุภัณฑ์รูปแบบต่างๆ หลากหลาย 
                    เพื่อตอบสนองความต้องการของลูกค้าทั้งในและต่างประเทศด้วยกระบวนการผลิตที่ได้มาตรฐานทุกขั้นตอนรวมถึงการตรวจสอบคุณภาพสินค้าอย่างเข้มข้น
                    เพื่อสร้างความเชื่อมั่นแก่ลูกค้าทุกราย</p>
                        <ul className={contactStyles.contactflex}>
                            <li><Icon icon={locationIcon} height={23} /></li>
                            <li><p className={contactStyles.plive}><b>ที่อยู่</b></p><p className={contactStyles.plivedes}>99/65 หมู่ที่ 2 ตำบลท่าเสา อำเภอกระทุ่มแบน จ.สมุทรสาคร 74110</p></li>
                        </ul>
                        <ul className={contactStyles.contactflex}>
                            <li><Icon icon={mobileIcon} height={23} /></li>
                            <li><p className={contactStyles.plive}><b>เบอร์โทรศัพท์</b></p><p className={contactStyles.plivedes}>034-471-614</p></li>
                        </ul>
                        <ul className={contactStyles.contactflex}>
                            <li><Icon icon={faxIcon} height={23} /></li>
                            <li><p className={contactStyles.plive}><b>แฟกซ์</b></p><p className={contactStyles.plivedes}>034-471-615</p></li>
                        </ul>
                  </Col>
                  <Col md={6} className={contactStyles.colmap}>
                  <iframe
                    title="Map ThaiPolyPack"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3877.674351415143!2d100.2456482!3d13.6166903!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2c13130ebb36b%3A0x4f9361024fec1965!2sTHAI%20POLY%20PACK%20COMPANY%20LIMITED!5e0!3m2!1sen!2sth!4v1628847169623!5m2!1sen!2sth"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0, borderRadius:"10px" }}
                    allowFullScreen=""
                    aria-hidden="false"
                  />
                  </Col>
              </Row>
          </Container>
      </main>
    )
}
        
export default Contact