import React from 'react'
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap"
import * as ourStyles from "./ourproduct.module.css"
import Containers from "./container"
import { StaticImage } from "gatsby-plugin-image"

// styles
const pageStyles = {
    color: "#000"
}
  
// markup
  const Ourproduct = () => {
    return (
      <main style={pageStyles}>
        <Container fluid="xl" className={ourStyles.ourpd}>
            <Row>
                <Col md={6}>
                    <h3 className={ourStyles.fontheader}>Our Product</h3>
                    <p className={ourStyles.fontp}>ด้วยความสามารถในการผลิตสินค้าที่ตรงตามความต้องการของลูกค้า ทำให้ TPP มีความหลากหลายของสินค้า</p>
                </Col>
                <Col md={6}></Col>
            </Row>
            <Row className={ourStyles.rowpd}>
                <Col>
                    <Tabs defaultActiveKey="flex" id="uncontrolled-tab-example">
                        <Tab eventKey="auto" title="Auto Packing Roll" className={ourStyles.conproduct}>
                            <Containers>
                                <Row>
                                    <Col md={6}>
                                        <StaticImage src="../images/product-auto.png" alt="ProductAuto" />
                                    </Col>
                                    <Col md={6} className={ourStyles.colproduct}>
                                        <h3>Auto Packing Roll</h3>
                                    </Col>
                                </Row>
                            </Containers>
                        </Tab>
                        <Tab eventKey="flex" title="Flexible Bag" className={ourStyles.conproduct}>
                            <Containers>
                                <Row>
                                    <Col md={4}>
                                        <StaticImage src="../images/product-flexible-01.png" alt="ProductFlexible01" />
                                        <p className={ourStyles.fontpp}>ซีล 3 ด้าน (Three side se)</p>
                                    </Col>
                                    <Col md={4}>
                                        <StaticImage src="../images/product-flexible-02.png" alt="ProductFlexible02" />
                                        <p className={ourStyles.fontpp}>ซีลกลาง (Center seal)</p>
                                    </Col>
                                    <Col md={4}>
                                        <StaticImage src="../images/product-flexible-03.png" alt="ProductFlexible03" />
                                        <p className={ourStyles.fontpp}>ซองตั้ง (Stand-up Pouch)</p>
                                    </Col>
                                </Row>
                            </Containers>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
      </main>
    )
  }
  
  export default Ourproduct