import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import * as QuaStyles from "./quality.module.css"
import { StaticImage } from "gatsby-plugin-image"
import Containers from "../components/container"

// styles
const pageStyles = {
    color: "#333333",
    backgroundColor: "#f4f4f4",
}
  
// markup
  const Quality = () => {
    return (
      <main style={pageStyles}>
        <Container fluid="xl" className={QuaStyles.qc}>
            <Row>
                <Col md={6}>
                    <h3 className={QuaStyles.header}>Quality Controll</h3>
                    <p className={QuaStyles.p}>บริษัท ให้ความสำคัญการควบคุมคุณภาพอย่างเป็นระบบและเคร่งครัด ในเรื่องความปลอดภัยของบรรจุภัณฑ์และมาตรฐานในการผลิต</p>
                        <ul className={QuaStyles.list}>
                            <li>Bonding and sealing test</li>
                            <li>Compression test</li>
                            <li>Defect inspection</li>
                            <li>Burst test</li>
                            <li>Coefficient of friction</li>
                        </ul>
                </Col>
                <Col md={6}>
                    <StaticImage src="../images/qc-1.png" alt="QC" />
                </Col>
            </Row>
            <Row className={QuaStyles.cer}>
                <Col md={12}>
                    <h3 className={QuaStyles.header}>Certificate</h3>
                    <Containers>
                        <Row>
                            <Col md={3} xs={6}>
                                <StaticImage src="../images/iso-9001-1.png" alt="ISO-9001" />
                                <p style={{fontWeight:"600",}}>ISO 9001</p>
                            </Col>
                            <Col md={3} xs={6}>
                                <StaticImage src="../images/gmp1.png" alt="GMP" />
                                <p style={{fontWeight:"600",}}>GMP</p>
                            </Col>
                            <Col md={3} xs={6}>
                                <StaticImage src="../images/hacpp1.png" alt="HACPP" />
                                <p style={{fontWeight:"600",}}>HACCP</p>
                            </Col>
                            <Col md={3} xs={6}>
                                <StaticImage src="../images/ghp.png" alt="GHPs" />
                                <p style={{fontWeight:"600",}}>GHPs</p>
                            </Col>
                        </Row>
                    </Containers>
                </Col>
            </Row>
        </Container>    
      </main>
    )
  }
  
  export default Quality